import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import CustomButton from 'components/CustomButton'
import Popup from 'components/Popup'
import { useAuth } from 'hooks/useAuth'
import useQuery from 'hooks/useQuery'

export default function SignUp() {
  const { push } = useHistory()
  const { spacing, palette } = useTheme()
  const [policyChecked, setPolicyChecked] = useState(false)
  const [optinChecked, setOptinChecked] = useState(true)
  const [error, setError] = useState(null)
  const { updateSubscription, temporaryAuth } = useAuth()
  const query = useQuery()

  if (!temporaryAuth) {
    push('/')
    return null
  }

  const user = temporaryAuth.user

  const handleClick = () => {
    updateSubscription({ optin_promotion_mail: optinChecked })
      .then(() => {
        const path = Boolean(query.continue) ? decodeURIComponent(query.continue) : '/'
        push(path)
      })
      .catch(setError)
  }

  return (
    <div
      style={{
        padding: spacing(6, 3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="body2">Logged in with your Runtrip account.</Typography>
      <Typography variant="body2">Would you like to use the service with this information?</Typography>
      <Typography style={{ margin: spacing(6, 0), fontWeight: 'bold' }} variant="body1">
        {user.name}
      </Typography>
      <div style={{ maxWidth: 340, marginBottom: spacing(3) }}>
        <FormControlLabel
          style={{ marginBottom: spacing(3) }}
          control={
            <Checkbox
              color="primary"
              checked={policyChecked}
              onChange={e => setPolicyChecked(e.target.checked)}
            />
          }
          label={
            <Typography variant="caption">
              I agree to provide the personal information used in the entry to the organizer and sponsors in order to provide prizes, etc.
            </Typography>
          }
        />
        <FormControlLabel
          style={{ marginBottom: spacing(3) }}
          control={
            <Checkbox
              color="primary"
              checked={optinChecked}
              onChange={e => setOptinChecked(e.target.checked)}
            />
          }
          label={
            <Typography variant="caption">
              Receive special offers from organizers and sponsors
            </Typography>
          }
        />
        <Typography
          style={{ marginBottom: spacing(3), color: palette.link, cursor: 'pointer' }}
          align="center"
          variant="caption"
          component="p"
          onClick={() => window.open(process.env.REACT_APP_SPONCER_URL)}
        >
          Click here for organizers and sponsors
        </Typography>
      </div>
      <CustomButton style={{ width: 210 }} onClick={handleClick} disabled={!policyChecked}>
        Get started
      </CustomButton>
      <Popup
        open={error !== null}
        onClose={() => setError(null)}
        title="エラーが発生しました"
        description="会員登録に失敗しました。"
        primaryText="OK"
        onClickPrimary={() => setError(null)}
      />
    </div>
  )
}
