import React, { useCallback, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import {makeStyles, useTheme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CustomButton from 'components/CustomButton'
import Popup from 'components/Popup'
import { useAPI } from 'hooks/useAPI'
import { EntryState, EventState, ParticipantStatus } from 'hooks/useEvent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as CloseIcon } from 'images/icons/close.svg'
import CustomLink from "@material-ui/core/Button";

export default function EventAction({ eventResource, user, onUpdateEvent }) {
  const [open, setOpen] = useState(false)
  const { palette, spacing } = useTheme()
  const { id } = useParams()
  const { push } = useHistory()
  const { client } = useAPI()
  const { event, activity, participant } = eventResource || {}
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.only('xs'))
  const [cancelSucceeded, setCancelSucceeded] = useState(false)
  const [cancelFailed, setCancelFailed] = useState(false)
  const [uploadMessage, setUploadMessage] = useState(false)

  const cancel = useCallback(() => {
    setOpen(false)
    client
      .delete(`/vrwc/event_participant/${participant.id}`)
      .then(res => {
        onUpdateEvent(res.data)
        setCancelSucceeded(true)
      })
      .catch(() => setCancelFailed(true))
  }, [client, participant, onUpdateEvent])

  const Button = ({ children, caption, disabled, onClick, variant = 'contained' }) => {
    if (disabled || !xs) {
      return (
        <div style={{ padding: spacing(3, 0) }}>
          {caption && (
            <Typography style={{ margin: spacing(6, 0) }} variant="body2">
              {caption}
            </Typography>
          )}
          <CustomButton variant={variant} disabled={disabled} onClick={onClick}>
            {children}
          </CustomButton>
        </div>
      )
    }

    return (
      <div
        style={{
          position: 'fixed',
          right: 0,
          left: 0,
          bottom: 0,
          padding: spacing(3),
          zIndex: 100,
        }}
      >
        <CustomButton variant={variant} onClick={onClick}>
          {children}
        </CustomButton>
      </div>
    )
  }

  const Action = useCallback(() => {
    switch (event.state) {
      // イベント開催前
      case EventState.BEFORE:
        // エントリー開始前
        if (event.entryState === EntryState.BEFORE) {
          return (
            <Button disabled caption="このイベントは申し込み受付前です">
              attend this event
            </Button>
          )
        } else if (event.entryState === EntryState.CLOSED) {
          return null
        }

        // 未ログインの場合
        if (!user) {
          const entryPath = encodeURIComponent(`/events/${id}/entry`)
          return (
            <Button variant="contained" onClick={() => push(`/login?continue=${entryPath}`)}>
              attend this event
            </Button>
          )
        }

        // 参加済みの場合
        if (participant.status === ParticipantStatus.REGISTERED) {
          return (
            <Button variant="outlined" onClick={() => setOpen(true)}>
              参加をキャンセルする
            </Button>
          )
        }

        // 参加人数上限に達した場合
        if (event.participant_count >= event.participant_count_max) {
          return null
        }

        return (
          <Button variant="contained" onClick={() => push(`/events/${id}/entry`)}>
            attend this event
          </Button>
        )

      case EventState.OPENING:
      case EventState.UPLOAD_OPENING:
        // 未ログインの場合はログインしてイベントページへ戻ってもらう
        if (!user) {
          const eventPath = encodeURIComponent(`/events/${id}`)
          return (
            <Button variant="contained" onClick={() => push(`/login?continue=${eventPath}`)}>
              ログインする
            </Button>
          )
        }

        // エントリー済みの場合はアップロード済みかで出しわけ
        if (participant.status === ParticipantStatus.REGISTERED && activity.uploaded) {
          // データアップロードをアプリのみに制限するかで出しわけ
          if (process.env.REACT_APP_DATA_UPLOAD_APP_ONLY) {
            // アプリのみ
            return (
              <Button variant="outlined" onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/EntryCompleted`)}>
                Upload results in app
              </Button>
            )
          } else {
            return (
              <Button
                disabled
                caption={
                  <>
                    このイベントの結果は既にアップロードされています。違う結果をアップロードするには
                    <Link style={{ color: palette.secondary.main }} to={`/trackers?eventId=${id}`}>
                      こちら
                    </Link>
                    から。
                  </>
                }
              >
                結果をアップロードする
              </Button>
            )
          }
        } else if (participant.status === ParticipantStatus.REGISTERED) {
          // データアップロードをアプリのみに制限するかで出しわけ
          if (process.env.REACT_APP_DATA_UPLOAD_APP_ONLY) {
            // アプリのみ
            return (
              <Button variant="outlined" onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/EntryCompleted`)}>
                Upload results in app
              </Button>
            )
          } else {
            return (
              <Button variant="outlined" onClick={() => setUploadMessage(true)}>
                結果をアップロードする
              </Button>
            )
          }
        }

        // エントリー期間中で参加人数上限に達していない場合
        if (
          event.entryState === EntryState.OPENING &&
          event.participant_count < event.participant_count_max
        ) {
          return (
            <Button variant="contained" onClick={() => push(`/events/${id}/entry`)}>
              attend this event
            </Button>
          )
        }

        return null
      default:
        return null
    }
  }, [event, activity, id, participant, user, push, palette, xs]) // eslint-disable-line react-hooks/exhaustive-deps

  const useStyles = makeStyles(theme => ({
    dialog: {
      '& .MuiDialog-paper': {
        margin: theme.spacing(4),
      },
    },
  }))
  const classes = useStyles()

  return (
    <>
      <Action />
      <Popup
        open={open}
        onClose={() => setOpen(false)}
        title="申し込みキャンセル"
        description="本当にこのイベントの申し込みをキャンセルしてもよろしいですか？"
        primaryText="はい"
        onClickPrimary={cancel}
        secondaryText="いいえ"
        onClickSecondary={() => setOpen(false)}
        buttonDirection="row"
      />
      {cancelSucceeded && (
        <Popup
          id="event_cancel"
          open
          onClose={() => setCancelSucceeded(false)}
          title="申し込みキャンセル"
          description="イベントの申し込みをキャンセルしました。"
          primaryText="OK"
          onClickPrimary={() => setCancelSucceeded(false)}
        />
      )}
      {cancelFailed && (
        <Popup
          open
          onClose={() => setCancelFailed(false)}
          title="エラーが発生しました"
          description="イベントの申し込みキャンセルに失敗しました。"
          primaryText="OK"
          onClickPrimary={() => setCancelFailed(false)}
        />
      )}
      {uploadMessage && (
          <Dialog id='popup' className={classes.dialog} open={true} onClose={() => setUploadMessage(false)}>
            <IconButton
                onClick={() => setUploadMessage(false)}
                style={{position: 'absolute', top: spacing(1), right: spacing(1)}}
            >
              <CloseIcon/>
            </IconButton>
            <DialogTitle style={{textAlign: 'center', padding: spacing(4, 10)}} disableTypography>
              <Typography variant="h3">お知らせ</Typography>
            </DialogTitle>
            <DialogContent id="dialog-content">
              2023年12月開催のイベントをもちまして、Webサイトからの完走・完歩証の発行を終了いたしました。
            </DialogContent>
            <CustomLink
                style={{marginTop: spacing(2), marginBottom: spacing(2)}}
                color="secondary"
                onClick={() => window.open("https://vrwc.runtrip.jp/news/736")}
            >詳細はこちら
            </CustomLink>
            <DialogContent>
              完走・完歩証の発行は、Runtripアプリ の「イベント」タブ>「イベント履歴」から記録をアップロードしてください。
            </DialogContent>
            <DialogActions>
              <CustomButton
                  style={{paddingBottom: spacing(4)}}
                  onClick={() => window.open("https://runtrip-web.onelink.me/Pprx/vrwctop")}>
                アプリをインストールする
              </CustomButton>
            </DialogActions>
            <DialogContent>
              Runtripアプリから完走・完歩証が発行できない場合（Runtripアプリをインストールできない場合等）は、こちらのフォームから申請ください。
            </DialogContent>
            <CustomLink
                style={{marginTop: spacing(2), marginBottom: spacing(4)}}
                color="secondary"
                onClick={() => window.open("https://req.qubo.jp/runtrip/form/vrwc")}
            >計測データ審査フォーム
            </CustomLink>
          </Dialog>
      )}
    </>
  )
}
