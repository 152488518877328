import React, { createContext, useCallback, useContext, useMemo, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const getUUID = () => {
  const UUID_KEY = 'VrwcUuid'
  const savedValue = localStorage.getItem(UUID_KEY)
  if (savedValue) {
    return savedValue
  }
  const newValue = uuidv4()
  localStorage.setItem(UUID_KEY, newValue)
  return newValue
}

const client = axios.create({
  baseURL: `${process.env.REACT_APP_RUNTRIP_API_ROOT}/v1`,
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': process.env.REACT_APP_RUNTRIP_API_KEY,
    'X-Accountid': process.env.REACT_APP_ACCOUNT_ID,
  },
})

let uuid: string | null = null
let userId: string | null = null
let accessToken: string | null = null

client.interceptors.request.use(req => {
  req.headers['X-Rt-Uuid'] = uuid

  if (accessToken) {
    req.headers['Authorization'] = `Bearer ${accessToken}`
  }

  if (userId) {
    req.headers['X-Userid'] = userId
    req.headers['X-Rt-Userid'] = userId
  }

  return req
})

const APIContext = createContext()

export function APIProvider({ children }) {
  const USER_ID_KEY = 'VrwcUserId'
  const ACCESS_TOKEN_KEY = 'VrwcUserToken'
  const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN_KEY, USER_ID_KEY])
  const [requestingCount, setRequestingCount] = useState(0)

  userId = cookies[USER_ID_KEY] || null
  accessToken = cookies[ACCESS_TOKEN_KEY] || null
  uuid = getUUID()

  useEffect(() => {
    const reqInterceptor = client.interceptors.request.use(req => {
      setRequestingCount(i => i + 1)
      return req
    })

    const resInterceptor = client.interceptors.response.use(
      res => {
        setRequestingCount(i => i - 1)
        return res
      },
      error => {
        console.log('error', error?.response ?? error)
        setRequestingCount(i => i - 1)
        return Promise.reject(error?.response ?? error)
      }
    )

    return () => {
      client.interceptors.request.eject(reqInterceptor)
      client.interceptors.response.eject(resInterceptor)
    }
  }, [])

  const requesting = useMemo(() => requestingCount > 0, [requestingCount])

  const setAccessToken = useCallback(
    ({ access_token, user, expires_in }) => {
      setCookie(USER_ID_KEY, user.id, { path: '/', expires: new Date(expires_in * 1000) })
      setCookie(ACCESS_TOKEN_KEY, access_token, { path: '/', expires: new Date(expires_in * 1000) })
    },
    [setCookie]
  )

  const removeCookies = () => {
    removeCookie(USER_ID_KEY)
    removeCookie(ACCESS_TOKEN_KEY)
  }

  const _getUUID = () => {
    return uuid
  }

  return (
    <APIContext.Provider
      value={{ client, setAccessToken, accessToken, userId, requesting, removeCookies, getUUID: _getUUID }}
      children={children}
    />
  )
}

export function useAPI() {
  return useContext(APIContext)
}
