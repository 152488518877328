import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Carousel from 'components/Carousel'
import CustomButton from 'components/CustomButton'
import SectionHeader from 'components/SectionHeader'

export default function Reward() {
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'))
  const { palette, spacing } = useTheme()

  if (!Boolean(process.env.REACT_APP_REWARD)) {
    return null
  }

  const items = JSON.parse(process.env.REACT_APP_REWARD)

  const Item = ({ name, caption, image, url, style }) => (
    <div style={{ style }}>
      <div>
        <div
          style={{
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            background: palette.primary.main,
            marginBottom: spacing(2),
          }}
        >
          <Typography variant="caption" style={{ color: '#fff', fontWeight: 'bold' }}>
            {caption}
          </Typography>
        </div>
        <div style={{ textAlign: 'center' }}>
          <img
            src={`/images/reward/${image}`}
            alt={name}
            style={{ width: 200, objectFit: 'cover' }}
          />
        </div>
        <Typography
          align={xs ? 'left' : 'center'}
          style={{ minHeight: 48, margin: spacing(2, 0, 3), fontWeight: 'bold' }}
        >
          {name}
        </Typography>
          {url && (
              <CustomButton arrow onClick={() => window.open(url)}>
                  Learn more
              </CustomButton>
          )}
      </div>
    </div>
  )

  return (
    <div
      style={{
        background: palette.background.paper,
        marginBottom: spacing(6),
        paddingBottom: spacing(6),
      }}
    >
      <SectionHeader primary="REWARD" />
      {xs ? (
        <Carousel>
          {items.map((props, i) => (
            <Item key={i} {...props} style={{ width: 200, minWidth: 200 }} />
          ))}
        </Carousel>
      ) : (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${xs ? 1 : 2}, 1fr)`,
            gap: spacing(6),
            padding: spacing(0, 6),
          }}
        >
          {items.map((props, i) => (
            <Item key={i} {...props} />
          ))}
        </div>
      )}
    </div>
  )
}
