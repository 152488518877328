import 'moment/locale/ja'

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import CustomButton from 'components/CustomButton'
import SectionHeader from 'components/SectionHeader'
import { useEvent } from 'hooks/useEvent'
import MainImage from 'components/MainImage'

export default function EntryComplete() {
  const { id } = useParams()
  const { spacing, palette } = useTheme()
  const [event, setEvent] = useState(null)
  const { getEvent } = useEvent()

  moment.locale('en-ca')

  useEffect(() => {
    getEvent(id).then(eventResource => setEvent(eventResource.event))
  }, [id, getEvent])

  if (!event) {
    return null
  }

  if (process.env.REACT_APP_DATA_UPLOAD_APP_ONLY) {
    return (
      <div style={{ background: palette.background.paper }}>
        <SectionHeader primary='App installation request' />
        <div style={{ padding: spacing(3) }}>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
              {moment(event.start_time).format('dddd, MMMM DD, YYYY') +
              ' - ' +
              moment(event.end_time).format('dddd, MMMM DD, YYYY')}
              <br />
              {event.title}
              <br />
              Applied to participate in the event.
            </Typography>
            <Typography variant='body2' align='center'>
              In order to participate in the event, you must install the Runtrip app.
            </Typography>
          </div>
        </div>
        <SectionHeader primary='For those who participate in the event for the first time' />
        <div style={{ padding: spacing(3) }}>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ fontWeight: 'bold', marginBottom: spacing(3) }} variant='body1'>
              If you don't have measuring equipment
            </Typography>
            <MainImage src='/images-static/easy_measurement_en.png' alt='' type='description' />
            <div
              style={{
                padding: spacing(3, 0),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
                  No measuring equipment? With the Runtrip app only, you can easily measure the distance you run, upload the results, and get a certificate of completion.
              </Typography>
              <CustomButton
                style={{ marginBottom: spacing(6) }}
                onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/EventDetail`)}
                arrow
              >
                INSTALL RUNTRIP FOR FREE
              </CustomButton>
            </div>
          </div>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ fontWeight: 'bold', marginBottom: spacing(3) }} variant='body1'>
              If you have measuring equipment
            </Typography>
            <MainImage src='/images-static/connect_device_en.png' alt='' type='description' />
            <div
              style={{
                padding: spacing(3, 0),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
                If you use a measuring device such as GARMIN, Apple Watch、Polar, Suunto, Fitbit, etc., you need to connect the measuring device with the Runtrip app in advance. Please note that measurement data cannot be uploaded if the connection is not completed.
              </Typography>
              <CustomButton
                style={{ marginBottom: spacing(6) }}
                onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/EventDetail`)}
                arrow
              >
                INSTALL RUNTRIP FOR FREE
              </CustomButton>
              <Typography style={{ color: palette.link }} variant='body1'>
                <Typography
                  style={{ marginBottom: spacing(3), color: palette.link, cursor: 'pointer' }}
                  onClick={() => window.open(`https://support.runtrip.jp/hc/ja/articles/360055504494`)}
                >
                  About how to connect measuring devices with the app
                </Typography>
              </Typography>
            </div>
          </div>
        </div>

        <SectionHeader primary='For those who have participated in the event' />
        <MainImage src='/images-static/confirm_entry_en.png' alt='' type='description' />
        <div style={{ padding: spacing(3) }}>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
              Open the Runtrip app and check the event history on My Page. Once you have confirmed the event you entered, let's prepare your body for the day of the event. We look forward to your participation.
            </Typography>
            <CustomButton
              style={{ marginBottom: spacing(6) }}
              onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/EventDetail`)}
              arrow
            >
              Open in app
            </CustomButton>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ background: palette.background.paper }}>
        <SectionHeader primary='アプリのインストールのお願い' />
        <div style={{ padding: spacing(3) }}>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',

            }}
          >
            <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
              {moment(event.start_time).format('YYYY年MM月DD日(ddd)') +
              '〜' +
              moment(event.end_time).format('YYYY年MM月DD日(ddd)')}
              <br />
              {event.title}
              <br />
              Applied to participate in the event.
            </Typography>
            <Typography variant='body2'>
              イベントに参加するためには、必ずRuntripアプリのインストールを行う必要があります。
            </Typography>
          </div>
        </div>
        <div style={{ padding: spacing(3) }}>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ fontWeight: 'bold', marginBottom: spacing(3) }} variant='body1'>
              アプリで完走証発行までより簡単に
            </Typography>
            <MainImage src='/images-static/easy_measurement.png' alt='' type='description' />
            <div
              style={{
                padding: spacing(3, 0),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
                Runtripアプリに計測機能があるため、計測機器を持っていなくても問題ありません。アプリ1つで、イベント当日の計測から結果アップロード、完走証の発行が簡単に行えます。
              </Typography>
              <CustomButton
                style={{ marginBottom: spacing(6) }}
                onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/vrwctop`)}
                arrow
              >
                無料アプリをインストール
              </CustomButton>
            </div>
          </div>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ fontWeight: 'bold', marginBottom: spacing(3) }} variant='body1'>
              外部計測デバイスも接続できる
            </Typography>
            <MainImage src='/images-static/connect_device.png' alt='' type='description' />
            <div
              style={{
                padding: spacing(3, 0),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
                GARMIN、Apple Watch、Polar、Suunto、Fitbitなどの計測機器を利用する方は、事前にRuntripアプリで計測機器接続を行う必要があります。接続が完了していない場合、計測データのアップロードは出来かねますのでご注意ください。
              </Typography>
              <CustomButton
                style={{ marginBottom: spacing(6) }}
                onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/vrwctop`)}
                arrow
              >
                無料アプリをインストール
              </CustomButton>
              <Typography style={{ color: palette.link }} variant='body1'>
                <Typography
                  style={{ marginBottom: spacing(3), color: palette.link, cursor: 'pointer' }}
                  onClick={() => window.open(`https://support.vrwc.runtrip.jp/hc/ja/articles/4416869373209`)}
                >
                  アプリで計測機器接続する方法について
                </Typography>
              </Typography>
            </div>
          </div>
        </div>

        <SectionHeader primary='イベントへの参加方法' />
        <MainImage src='/images-static/confirm_entry.png' alt='' type='description' />
        <div style={{ padding: spacing(3) }}>
          <div
            style={{
              padding: spacing(3, 0),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant='body2' style={{ marginBottom: spacing(6) }}>
              Runtripアプリを開いて、画面下部のタブから「イベント」を選択し、上部の「イベント履歴」を確認してください。エントリーしたイベントを確認できたら、あとはイベント当日に向けてカラダの調子を整えましょう。あなたのご参加を楽しみにお待ちしております。
            </Typography>
            <CustomButton
              style={{ marginBottom: spacing(6) }}
              onClick={() => window.open(`https://runtrip-web.onelink.me/Pprx/vrwctop`)}
              arrow
            >
              アプリで開く
            </CustomButton>
          </div>
        </div>
      </div>
    )
  }
}
