import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useAPI } from 'hooks/useAPI'
import useQuery from 'hooks/useQuery'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const { push } = useHistory()
  const [user, setUser] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const [temporaryAuth, setTemporaryAuth] = useState(null)
  const { client, setAccessToken, accessToken, removeCookies, getUUID } = useAPI()
  const { search } = useLocation()
  const query = useQuery()

  useEffect(() => {
    if (accessToken) {
      // ログイン状態
      client
        .get('/vrwc/user/me')
        .then(res => {
          setUser(res.data)
          setInitialized(true)
        })
        .catch(error => {
          removeCookies()
          setInitialized(true)
        })
    } else {
      // 非ログイン状態
      setInitialized(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkSubscription = useCallback(async (payload) => {
    const uid = payload.user.id
    const uuid = getUUID()

    try {
      const { status } = await client.get('/vrwc/subscription', {
        headers: {
          Authorization: `Bearer ${payload.access_token}`,
          'X-Rt-Uuid': uuid,
          'X-Userid': uid,
          'X-Rt-Userid': uid,
        }
      })

      // VRWCの購読設定がされている場合、TOP画面に遷移する
      if (status === 200) {
        // ログイン情報を確定させる
        setAccessToken(payload)

        // この時点でログインは成功しているため、既存のuseEffectに干渉しないようにこちらでログインユーザをセット
        const { data: user } = await client.get('/vrwc/user/me')
        setUser(user)

        const path = Boolean(query.continue) ? decodeURIComponent(query.continue) : '/'
        push(path)
      }
    } catch(e) {

      // Runtripユーザーで認証したがsubscriptionの設定が存在しない場合、subscriptionの登録画面へ遷移させる
      if (e.status === 404) {
        // 一時的な認証情報を保持
        setTemporaryAuth(payload)

        push({ pathname: '/signup', search })
        return
      }

      throw e
    }
  }, [client, getUUID, push, query.continue, search, setAccessToken])


  const signInWithRuntrip = useCallback(
    async ({ user, access_token, expires_in }) => {
      checkSubscription({user, access_token, expires_in})
    },
    [checkSubscription]
  )

  const signInWithSns = useCallback(
    async data => {
      const response = await client.post('/auth/runtrip/sns_user/token', data)
      const res = await signInWithRuntrip(response.data)
      return res
    },
    [client, signInWithRuntrip]
  )

  const signInWithEmail = useCallback(
    data => {
      return client
        .post('/auth/runtrip/email_user/token', data)
        .then(res => signInWithRuntrip(res.data))
    },
    [client, signInWithRuntrip]
  )

  const updateSubscription = useCallback(
    async (data) => {
      if (!temporaryAuth) {
        throw new Error('User has not logged in before subscription')
      }

      // ログイン情報を確定させる
      setAccessToken(temporaryAuth)

      await client.post('/vrwc/subscription', data)

      const { data: user } = await client.get('/vrwc/user/me')
      setUser(user)
    },
    [client, setAccessToken, temporaryAuth]
  )

  const signOut = useCallback(() => {
    removeCookies()
    setUser(null)
    push('/')
  }, [removeCookies, push])

  return (
    <AuthContext.Provider
      value={{ initialized, user, signInWithEmail, signInWithSns, updateSubscription, signOut, temporaryAuth }}
      children={children}
    />
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
