import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import ButtonBase from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { useAuth } from 'hooks/useAuth'
import loginImage from 'images/icons/login.png'
import userImage from 'images/icons/user.png'

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
  },
  container: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    padding: theme.spacing(3, 0),
    display: 'flex',
    position: 'relative',
  },
  logoWrapper: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      flex: 1,
      margin: 0,
      textAlign: 'center',
    },
  },
  logo: {
    height: 40,
    objectFit: 'contain',
  },
  button: {
    marginRight: theme.spacing(3),
    position: 'absolute',
    right: 0,
  },
  icon: {
    '& path': {
      stroke: theme.palette.action.active,
    },
  },
}))

export default function NavBar() {
  const classes = useStyles()
  const { push } = useHistory()
  const { user, initialized } = useAuth()

  const Button = ({ children, image, onClick }) => (
    <ButtonBase
      className={classes.button}
      onClick={onClick}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <img alt={children} src={image} width="24" height="24" />
      <Typography variant="caption" color="textPrimary" component="p" style={{ minWidth: 60 }}>
        {children}
      </Typography>
    </ButtonBase>
  )

  const Menu = useMemo(() => {
    if (!initialized) {
      return null
    }
    if (user) {
      return (
        <Button image={userImage} onClick={() => push('/users/me')}>
          MyPage
        </Button>
      )
    }
    return (
      <Button image={loginImage} onClick={() => push('/login')}>
        Login
      </Button>
    )
  }, [initialized, user, push])

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.logoWrapper}>
          <ButtonBase onClick={() => push('/')}>
            <img src="/images/logo.png" alt="Logo" className={classes.logo} />
          </ButtonBase>
        </div>
        {Menu}
      </div>
    </div>
  )
}
