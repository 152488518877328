import React from 'react'

export default function Beer({color}) {
    return (
        <svg height='60' viewBox="0 0 80.1 115.67" xmlns="http://www.w3.org/2000/svg" >
            <g>
                <polygon points="55.54 15.67 60.09 1.6 55.77 .62 55.54 15.67"
                         fill={color}
                         stroke={color}
                         strokeMiterlimit={10}
                />
                <polygon points="62.15 17.87 74.42 9.6 70.91 5.67 62.15 17.87"
                         fill={color}
                         stroke={color}
                         strokeMiterlimit={10}/>
                <polygon points="65.6 24.77 78.84 19.53 79.53 24.08 65.6 24.77"
                         fill={color}
                         stroke={color}
                         strokeMiterlimit={10}/>
            </g>
            <g>
                <g>
                    <path
                        d="m53.38,24.3c-1.02-1.27-1.72-3.35-1.94-5.71-.19-2.02-1.85-3.55-3.87-3.55H9.35c-2.02,0-3.68,1.53-3.87,3.55-.22,2.36-.93,4.44-1.94,5.71-2.32,2.89-3.54,6.26-3.54,9.74v70.38c0,1.97,1.13,3.75,2.89,4.53.99.44,1.83,1.61,2.28,3.2.59,2.07,2.53,3.51,4.7,3.51h37.19c2.17,0,4.1-1.45,4.7-3.52.46-1.59,1.29-2.76,2.28-3.2,1.75-.78,2.89-2.56,2.89-4.53V34.04c0-3.48-1.23-6.85-3.54-9.74Z"
                        fill={color}/>
                    <path
                        d="m52.96,54.43c-3.48-10.62-13.28-17.74-24.5-17.74S7.44,43.81,3.97,54.43v24.13c3.48,10.62,13.28,17.74,24.5,17.74s21.02-7.12,24.5-17.74v-24.13Z"
                        fill={`#fff`}/>
                </g>
                <g>
                    <path
                        d="m19.31,68.04c0,2.26-1.24,3.39-3.72,3.39h-5.34v-10.97h5.16c2.27,0,3.41.97,3.41,2.92,0,.44-.14.88-.42,1.34-.26.4-.55.69-.88.88,1.2.49,1.79,1.31,1.79,2.45Zm-3.22-4.3c0-.62-.41-.93-1.22-.93h-1.96v1.96h1.98c.41,0,.72-.12.94-.35.17-.18.26-.41.26-.68Zm.41,4.18c0-.69-.48-1.03-1.43-1.03h-2.16v2.23h2.24c.9,0,1.35-.4,1.35-1.19Z"
                        fill={color}/>
                    <path d="m28.66,71.43h-7.83v-10.97h7.83v2.41h-5.12v1.73h4.49v2.42h-4.49v1.99h5.12v2.42Z"
                          fill={color}/>
                    <path d="m38.27,71.43h-7.83v-10.97h7.83v2.41h-5.12v1.73h4.49v2.42h-4.49v1.99h5.12v2.42Z"
                          fill={color}/>
                    <path
                        d="m49.64,71.43h-2.99l-1.34-3c-.3-.66-.9-1-1.81-1h-.75v3.99h-2.71v-10.97h5.25c1,0,1.84.26,2.5.77.78.6,1.17,1.47,1.17,2.61,0,1.38-.65,2.36-1.95,2.93.48.27.86.72,1.14,1.35l1.48,3.31Zm-6.88-6.27h2.21c.44,0,.76-.15.97-.45.16-.21.24-.46.24-.75s-.08-.52-.24-.71c-.21-.26-.53-.39-.97-.39h-2.21v2.29Z"
                        fill={color}/>
                </g>
                <g>
                    <path d="m46.41,57.17c-2.92-7.93-9.86-13.51-17.95-13.51s-15.02,5.58-17.95,13.51"
                          fill={`none`}
                          stroke={color}
                          strokeMiterlimit={10}
                          strokeWidth={'3px'}/>
                    <path d="m10.52,73.96c2.92,7.93,9.86,13.51,17.95,13.51s15.02-5.58,17.95-13.51"
                          fill={`none`}
                          stroke={color}
                          strokeMiterlimit={10}
                          strokeWidth={'3px'}
                    />
                </g>
            </g>
        </svg>
    )
}
