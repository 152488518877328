import React, { useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

function InternalDatePicker({ value, onChange, error }) {
  const { spacing } = useTheme()
  const [year, setYear] = useState("")
  const [month, setMonth] = useState("")
  const [date, setDate] = useState("")

  useEffect(() => {
    if (year && month && date) {
      const birthday = moment()
        .year(Number(year))
        .month(Number(month) - 1)
        .date(Number(date))
        .format('YYYY-MM-DD')
      onChange(birthday)
    }
  }, [year, month, date, onChange])

  useEffect(() => {
    const birthdayValue = moment(value, 'YYYY-MM-DD')
    if (birthdayValue.isValid()) {
      setYear(`${birthdayValue.year()}`)
      setMonth(`${birthdayValue.month() + 1}`)
      setDate(`${birthdayValue.date()}`)
    }
  }, [value])

  const years = useMemo(() => {
    const from = moment().add(-6, 'years').year()
    const to = moment().add(-100, 'years').year()
    const _years = []
    for (let i = from; i >= to; i--) {
      _years.push(i)
    }
    return _years
  }, [])

  const months = useMemo(() => {
    return [...Array(12).keys()].map(i => i + 1)
  }, [])

  const dates = useMemo(() => {
    let endOfMonth = 31
    if (year && month) {
      endOfMonth = moment()
        .year(year)
        .month(month - 1)
        .endOf('month')
        .date()
    }
    return [...Array(endOfMonth).keys()].map(i => i + 1)
  }, [year, month])

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        id="birthdatey"
        color="secondary"
        select
        SelectProps={{ native: true }}
        style={{ flex: 2, marginRight: spacing(2) }}
        value={year}
        onChange={e => setYear(e.target.value)}
        error={error !== undefined}
      >
        <option></option>
        {years.map(x => (
          <option key={x}>{x}</option>
        ))}
      </TextField>
      <Typography variant="body2">Year</Typography>
      <TextField
        color="secondary"
        select
        SelectProps={{ native: true }}
        style={{ flex: 1, marginRight: spacing(2), marginLeft: spacing(4) }}
        value={month}
        onChange={e => setMonth(e.target.value)}
        error={error !== undefined}
      >
        <option></option>
        {months.map(x => (
          <option key={x}>{x}</option>
        ))}
      </TextField>
      <Typography variant="body2">Month</Typography>
      <TextField
        color="secondary"
        select
        SelectProps={{ native: true }}
        style={{ flex: 1, marginRight: spacing(2), marginLeft: spacing(4) }}
        value={date}
        onChange={e => setDate(e.target.value)}
        error={error !== undefined}
      >
        <option></option>
        {dates.map(x => (
          <option key={x}>{x}</option>
        ))}
      </TextField>
      <Typography variant="body2">Day</Typography>
    </div>
  )
}

export default function DatePicker({ id, label, control, rules, error, helperText }) {
  const { spacing } = useTheme()

  return (
    <div style={{ padding: spacing(2, 0) }}>
      <Typography variant="caption" color={error === undefined ? 'textSecondary' : 'error'}>
      {label}
      </Typography>

      <FormControl style={{ width: '100%' }} error={error !== undefined}>
        <Controller
          as={<InternalDatePicker error={error} />}
          defaultValue=""
          control={control}
          name={id}
          rules={rules}
        />
        {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
        {error && <FormHelperText error>必須項目です</FormHelperText>}
      </FormControl>
    </div>
  )
}
