import 'moment/locale/ja'

import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import moment from 'moment'

import EventAction from 'components/EventAction'
import EventInfoListItem from 'components/EventInfoListItem'
import MainImage from 'components/MainImage'
import SectionHeader from 'components/SectionHeader'
import Share from 'components/Share'
import { useAuth } from 'hooks/useAuth'
import { EntryState, EventState, ParticipantStatus, useEvent } from 'hooks/useEvent'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import MultilineText from '../components/MultilineText'

export default function Event() {
  const { push } = useHistory()
  const { palette, spacing } = useTheme()
  const [eventResource, setEventResource] = useState(null)
  const { user } = useAuth()
  const { id } = useParams()
  const { getEvent, initEvent } = useEvent()
  const { event, participant } = eventResource || {}

  moment.locale('en-ca')

  useEffect(() => {
    getEvent(id)
      .then(setEventResource)
      .catch(error => push('/404'))
  }, [id, getEvent, push])

  const date = useMemo(() => {
    if (!event) return ''
    if (moment(event.start_time).isSame(event.end_time, 'day')) {
      return moment(event.start_time).format('YYYY/MM/DD')
    } else {
      return `${moment(event.start_time).format('YYYY/MM/DD')} ~ ${moment(event.end_time).format(
        'YYYY/MM/DD'
      )}`
    }
  }, [event])

  if (!eventResource) {
    return null
  }

  const imageOverlayText = (state, entryState, isFullParticipants) => {
    if (participant.status === ParticipantStatus.REGISTERED) {
      return null
    }

    switch (state) {
      case EventState.FINISHED:
      case EventState.UPLOAD_OPENING:
        return 'イベントは終了しました'
      default:
        if (entryState === EntryState.CLOSED) {
          return 'イベントの受付は終了しました'
        }
        return isFullParticipants ? '参加人数上限に達しました' : null
    }
  }

  const capacity = number => {
    // 定員 1,000,000 の場合 "上限なし" と表記
    return number === 1000000 ? 'Without limit' : `${number}`
  }

  const limit = time => {
    // 制限時間なし
    if (time === 0) {
      return 'No limit'
    }
    const hour = parseInt(time / 60)
    const min = time % 60
    return min > 0 ? `${hour}時間${min}分` : `${hour}時間`
  }

  const distance = number => {
    // 距離 0 の場合 "制限なし" と表記
    return number === 0 ? '制限なし' : `${event.distance / 1000}km`
  }

  return (
    <div>
      <MainImage
        src={event.cover_image_url}
        alt="イベント画像"
        type="event"
        overlayText={imageOverlayText(
          event.state,
          event.entryState,
          event.participant_count >= event.participant_count_max
        )}
      />

      {Boolean(process.env.REACT_APP_DATA_UPLOAD_APP_ONLY) && (
        <div style={{ background: palette.background.paper }}>
          <div
            style={{
              margin: spacing(3, 3),
              padding: spacing(3),
              background: "#E6FAFF",
              border: "1px solid #42B8E4",
              borderRadius: "8px",
            }}
          >
            <Typography variant="body2">
              <div
                style={{
                  fontSize: '15px',
                  fontWeight: '700',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ErrorIcon
                  style={{
                    color: '#42B8E4',
                    marginRight: spacing(1),
                  }}
                />
                <div
                  style={{
                    padding: spacing(3)
                  }}
                >
                  <MultilineText>
                    This event requires pre-installation of the Runtrip app (free) after applying for participation.
                  </MultilineText>
                </div>
              </div>
            </Typography>
          </div>
        </div>
      )}

      <SectionHeader primary={event.title} secondary={date} />
      <Share />
      <div style={{ background: palette.background.paper, padding: spacing(3) }}>
        <EventInfoListItem label="Event" value={event.competitionText} />
        <EventInfoListItem label="Distance" value={distance(event.distance)} />
        <EventInfoListItem
          label="Event Period"
          value={
            moment(event.start_time).format('dddd, MMMM DD, YYYY [at] hh:mm a') +
            ' - ' +
            moment(event.end_time).format('dddd, MMMM DD, YYYY [at] hh:mm a')
          }
        />
        <EventInfoListItem label="Time limit" value={limit(event.limit_time)} />
        <EventInfoListItem label="Participation fee" value={event.price > 0 ? `${event.price}円` : 'free'} />
        <EventInfoListItem
          label="Application Period"
          value={
            moment(event.entry_start_datetime).format('dddd, MMMM DD, YYYY [at] hh:mm a') +
            ' - ' +
            moment(event.entry_end_datetime).format('dddd, MMMM DD, YYYY [at] hh:mm a')
          }
        />
        <EventInfoListItem label="Capacity" value={capacity(event.participant_count_max)} />
        <EventInfoListItem label="Event details" value={event.description} markdown />
        <EventInfoListItem label="Notes" value={event.attention} markdown />
        <EventAction
          eventResource={eventResource}
          user={user}
          onUpdateEvent={value => setEventResource(initEvent(value))}
        />
      </div>
    </div>
  )
}
